import React from 'react'
import { ThemeProvider } from '@leshen/ui'
import GlobalStyles from './CustomStyles'
import siteTheme from 'gatsby-theme-centurylink/theme'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>
    <GlobalStyles theme={siteTheme} />
    {children}
  </ThemeProvider>
)

export default Wrapper
